@import 'antd/dist/reset.css';

@font-face {
    font-family: 'LXGWWenKaiGB';
    src: url('./asset/LXGWWenKaiGB-Regular.woff') format('woff');
}

* {
    font-family: "LXGWWenKaiGB";
}

.underline-button:hover {
    background-color: #000000;
}

.underline-button:hover::after {
    transform: scaleY(1); /* 鼠标悬停时显示下划线 */
    bottom: -4px; /* 调整下划线与文字的间距 */
}

.underline-button::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0; /* 可以调整与按钮底部的间距 */
    width: 100%;
    height: 1px; /* 下划线的厚度 */
    background-color: white; /* 下划线的颜色 */
    transform: scaleY(0); /* 初始状态下线的高度为0 */
    transform-origin: bottom;
}
